html,
body,
#root,
#root .layout {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #f0f2f5;
}

* {
  transition: none !important;
}

body .ant-btn-danger.invert,
body .ant-btn-danger.invert:focus {
  background-color: #f5222d;
  border-color: #f5222d;
  color: #fff;
}

body .ant-row,
body #root > .ant-row-flex {
  height: 100%
}

body .align-left .ant-form-item-label {
  text-align: left;
}

body .ant-row.ant-form-item.no-margin {
  margin: 0;
}

body .ant-input,
body .ant-radio-inner,
body .ant-select-selection,
body .ant-select-dropdown
{
  color: #111;
  border: 1px solid #999;
}

body .ant-radio-wrapper,
body .ant-select-dropdown-menu-item,
body .ant-select-selection__choice__content {
  color: #111;
}

body .ant-select-dropdown-menu-item-selected,
body .ant-select-dropdown-menu-item-selected:hover,
body .ant-select-dropdown-menu-item-selected:focus
{
  color: white;
  font-weight: normal;
  background-color: #40a9ff;
}

body .ant-radio-group {
  padding-left: 8px;
  border: 1px solid transparent;
}

body .ant-radio-group:focus-within {
  border: 1px solid #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

body .ant-input:hover,
body .ant-input:focus,
body .ant-select-selection:hover,
body .ant-select-selection:focus,
body .ant-select-selection__rendered:hover,
body .ant-select-selection__rendered:focus
{
  border-color: #40a9ff;
}

body .ant-table {
  color: rgba(0,0,0,0.85);
}

body .ant-table td.no-wrap,
body .ant-table th.no-wrap {
  white-space: nowrap;
}

.headerLeft {
  float: left;
  height: 46px;
  display: inline-block;
  text-align: left;
}

.headerRight {
  float: right;
  height: 46px;
  display: inline-block;
  text-align: right;
}

.headerLeft .ant-menu-horizontal {
  display: inline-block;
}

.headerRight .currentUser,
.headerRight .logout {
  display: inline-block;
}

.currentUser {
  margin-right: 24px;
  color: white;
}
